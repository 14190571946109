import React, { Component } from 'react';

import './styles.css';

const questions = [
  {question: "What is the wedding attire?", answers: ["The dress code is formal attire."]},
  {question: "Will the wedding be outdoors or indoors?", answers: ["The ceremony will take place outside, weather permitting. Women may consider block heels to be able to walk comfortably in the grass."]},
  {question: "What date do I need to RSVP by?", answers: ["To be announced"]},
  // {question: ["Can I RSVP online or do I have to mail in an RSVP card?", answers: "Please Rsvp online, navigate to the RSVP section of our website above."]},
  {question: "What is the address of the wedding venue?", answers: ["Chateau Barthelemy, Douaville, 78660 Paray-Douaville, France"]},
  {question: "Is the venue wheelchair accessible?", answers: ["Yes."]},
  {question: "Is there parking available near the wedding venue?", answers: ["Yes, there is an onsite parking lot."]},
  {question: "Have you reserved blocks of rooms at one or more hotels?", answers: ["We will not be offering a hotel block. Please see the Accommodations & Transportation section for more details."]},
  {question: "Will there be a shuttle to and/or from the venue?", answers: ["Yes, details to be determined. See the Accommodations & Transportation section above."]},
  {question: "Will the ceremony be officiated in French or English?", answers: ["Our ceremony will be officiated primarily in English."]},
  // {question: "Are children invited?", answers: ["Please refer to your invitation."]},
  // {question: "Will there be childcare?", answers: ["TBD"]},
  {question: "Any tips for traveling in France?", answers: ["Check your passport! Your passport should be valid for at least 3 months after the date you intend to leave the EU and it must have been issued within the last 10 years.","Bring euros! While most businesses take credit/debit cards in Paris, we suggest carrying small cash for tipping and other scenarios where it might come in handy (e.g. your daily €1.20 croissant). Also note that French gas stations don't always accept international credit cards, so if you rent a car, you might need euros to pay for gas.", "The Paris metro system is the ideal way to get around the city, just be cautious of pickpockets :)"]}
];


function Question ( { question }) {
  const answers = question.answers.map((a, index) => <p key={index} class="answer">{a}</p>)
  return (
    <>
      <div class="question-container">
        <p class="prompt">{question.question}</p>
        {answers}
      </div>
    </>);
}

function QuestionsList() {
  const questionViews = questions.map((q, index) =>  <Question key={index} question={q} /> );
  return (
  <>
    {questionViews}
  </>);

}

class Faq extends Component {
  render() {
    return (
    <>
      <hr class="section-divider"/>
      <div id="faq" className="faq" class="section">
        <h1 class="section-title">Frequently Asked Questions</h1>
        <QuestionsList></QuestionsList>
      </div>
    </>
    );
  }
}
export default Faq;