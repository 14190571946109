import { HomeContainer } from './styledComponents'
import React, { Component, useState, useEffect } from 'react';
import Welcome from '../Welcome/Welcome';
import Travel from '../Travel/Travel';
import Rsvp from '../Rsvp/Rsvp';
import Schedule from '../Schedule/Schedule';
import Registry from '../Registry/Registry';
import Ceremony from '../Ceremony/Ceremony';
import PhotoSection from '../PhotoSection/PhotoSection';
import Faq from '../Faq/Faq';
import PasswordPromptDialog from '../Password/PasswordPromptDialog';

import "./styles.css";

export default function Home() {
  const [isAuth, setAuthed] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const isRsvpOn = urlParams.has("rsvp")
    ? urlParams.get("rsvp") == "on"
    : false;

  if (isAuth) {
    return (
      <>
       <div id="home" className="App">
        <div className="myDiv">
          <div className="bg"></div>
          <HomeContainer>
            <h1 className="names">Shira & Gabriel</h1>
            <p className="details">August 31, 2025</p>
            <p className="details">Paris, France</p>
          </HomeContainer>
        </div>
        <Welcome />
        <PhotoSection photo={"engagementday"}/>
        <Schedule />
        <PhotoSection photo={"mirror"}/>
        <Travel />
        <PhotoSection photo={"via-ferrata"}/>
        {isRsvpOn ? <Rsvp isSection={true}/> : <></>}
        <Registry />
        <PhotoSection photo={"laugh"}/>
        <Faq />
        <PhotoSection photo={"rivver"}/>
        {/* <Ceremony /> */}
       </div>
      </>);
  } else {
    return (
      <>
        <PasswordPromptDialog setAuth={setAuthed} />
      </>);
    }
}