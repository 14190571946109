import React, { Component } from 'react';
import { FaDirections } from "react-icons/fa";

import './styles.css';

const reception = {
  name: 'Wedding Ceremony & Reception',
  location: 'Chateau Barthélemy | Douaville, 78660 Paray-Douaville, France',
  address: 'Chateau Barthélemy, Douaville, 78660 Paray-Douaville, France',
  // display_address: 'Chateau Barthélemy | Douaville, 78660 Paray-Douaville, France',
  date: "August 31st, 2025",
  attire: "Formal",
  events: [
    { name: "Guests are welcome to arrive", time: "starting at 17:00" },
    // { name: "Ceremony to begin promptly", time: "at 17:45" },
    { name: "Dinner and dancing", time: "to follow" }
  ],
  extras: [
    'Complimentary shuttles will be provided to and from the venue on wedding day.',
    'Pick up/dropoff location and times are to be determined.',
    'Shuttle RSVP Information to come.'
  ]
}

function EventCard({ event }) {
  const eventRows = event.events.map((d, index) =><p key={index} class="event-list">{d.name} {d.time}</p>);
  const eventLink = "https://maps.google.com/?q=".concat(event.address);

  return (
    <>
    <div class="card">
      <div class="container">
        <h4 className="event-title">{event.name}</h4>
        <p class="event-details">{event.location}</p>
        {'display_address' in event ? <p class="event-details">{event.display_address}</p> : <></>}
        <p class="event-details">{event.date}</p>
        { eventRows }
        { 'attire' in event ? <p class="event-details">Attire: {event.attire}</p> : <></>}
        { 'extras' in event ? event.extras.map((d) =><><p class="event-list">{d}</p></>) : <></>}
      </div>
      <a type="button" class="directions btn btn-dark" target="_blank" href={eventLink}>
        <FaDirections className="directions-icon"/>
        Directions
      </a>
    </div>
    </>
    );
}


function Events() {
  const events = [reception];
  const eventRows = events.map((d, index) =><EventCard key={index} event={d} />);

  return (
    <>
      <div class="flex-container">
        { eventRows }
      </div>
    </>
    );
}

class Schedule extends Component {

  render() {
    return (
    <>
      <hr class="section-divider"/>
      <div id="schedule" className="schedule section schedule-section">
        <h1 class="section-title">Schedule</h1>
        <Events />
      </div>
    </>
    );
  }
}
export default Schedule;