import React, { useState, useEffect, Component } from 'react';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, RadioGroup, Radio, Button, FormControl, FormLabel, FormGroup } from '@mui/material';
import Rsvp from '../Rsvp/Rsvp';
import axios from 'axios';
import PasswordPromptDialog from '../Password/PasswordPromptDialog';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import './styles.css';

var foundGuest = false;
var guest = null;
var foundPotentialGuests = false;
var potentialGuests = [];

var actualEvents = [];

let eventDetails = [
    {name: "Shabbat", description: "Friday August 29th 2025 at 7:00pm in Paris, France."},
    {name: "WelcomeParty", description: "Saturday August 30th 2025 at 7:00pm, in Paris, France."},
    {name: "Wedding", description: "Sunday August 31st 2025 at 4:30pm in Paray-Douaville, France."},
    {name: "Brunch", description: "Monday September 1st 2025 at 11am in Paray-Douaville, France."}];

var guestCheckboxes = <></>;

function PotentialGuestSelect({ potentialGuests, selectGuest }) {
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();

  var selectedGuestName;
  function handleSelect(event) {
    selectedGuestName = event.target.value;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.target.reset();
    const route = '/rsvp?guest=' + selectedGuestName;
    navigate(route); 
    navigate(0);
  };

  let potentialGuestsRow = potentialGuests.map((d) =><>
    <li key={d.id}><FormControlLabel value={d.name} control={<Radio />} label={d.name} /></li></>);
  

  return(
      <>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Found potential guests: </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={handleSelect}>
              <ul style={{ listStyleType: 'none'}}>
                { potentialGuestsRow }
              </ul>
            </RadioGroup>
            <Button type="submit" variant="outlined">
              Submit
            </Button>
          </FormControl>
        </form>
      </>);
}

function BaseEventForm({ attending, guests, event, toggleUser, toggleAttendance }) {
  guestCheckboxes = guests.filter((g) => g != undefined).map((g) => <ul key={g.id}><FormControlLabel control={<Checkbox name={g.id} onChange={(e) => handleGuestChange(e, g.id) }/>} label={g.name} /></ul>);

  const handleChange = (e) => {
    toggleAttendance(e.target.value);
  };

  function handleGuestChange(e, userId) {
    toggleUser(userId);
  }

  const thisEventDetails = eventDetails.filter(obj => obj.name==event).map(({ description }) => description);
  var formLabel = <><p id="demo-simple-select-label">{event}: {thisEventDetails}</p></>;

  return (<>
    { formLabel }
    <Box sx={{ minWidth: 180 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select attendance</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={attending}
          label="Select attendance"
          onChange={handleChange}>
          <MenuItem value={''}>Please select your response</MenuItem>
          <MenuItem value={'false'}>Will not attend</MenuItem>
          <MenuItem value={'true'}>Will attend</MenuItem>
        </Select>
      </FormControl>
      <FormGroup>
        { attending == 'true' && guestCheckboxes }
      </FormGroup>
    </Box>
  </>);
}

function toggleTheAttendance(newAttending, setAttending, setAttendingUsers) {
  if (newAttending == 'true') {
    setAttending('true');
  } else if (newAttending == 'false') {
    setAttending('false');
    setAttendingUsers('');
  } else if (newAttending == '') {
    setAttendingUsers('');
    setAttending('');
  }
}

function updateAttendingUsers(attendingUsers, user, setAttendingUsers, setUser) {
  if (user == '') return;
    let newAtt = attendingUsers;
    if (newAtt.includes(user)) {
      newAtt = newAtt.replace(user, '');
    } else {
      newAtt += (user + ';');
    }
    setAttendingUsers(newAtt)
    setUser('');
}

function ShabbatForm({ guests, updateResponses }) {
  const [attending, setAttending] = React.useState('');
  const [user, setUser] = React.useState('');
  const [attendingUsers, setAttendingUsers] = React.useState('');

  function toggleAttendance(val) {
    toggleTheAttendance(val, setAttending, setAttendingUsers);
  }

  updateResponses({'event': 'Shabbat', 'attending': attending, 'users': []})

  useEffect(() => {
    updateResponses({'event': 'Shabbat', 'attending': attending, 'users': attendingUsers.split(';')})
  }, [attendingUsers, setAttendingUsers]);

  useEffect(() => {
    updateAttendingUsers(attendingUsers, user, setAttendingUsers, setUser);
  }, [user, setUser]);

  function toggleUser(userId) {
    setUser(userId);
  }

  return (<>
    <BaseEventForm attending={attending} guests={guests} event="Shabbat" toggleAttendance={toggleAttendance} toggleUser={toggleUser}></BaseEventForm>
    </>
  )
}

function WelcomePartyForm({ guests, updateResponses }) {
  const [attending, setAttending] = React.useState('');
  const [user, setUser] = React.useState('');
  const [attendingUsers, setAttendingUsers] = React.useState('');

  function toggleAttendance(val) {
    toggleTheAttendance(val, setAttending, setAttendingUsers);
  }

  updateResponses({'event': 'WelcomeParty', 'attending': attending, 'users': []})

  useEffect(() => {
    updateResponses({'event': 'WelcomeParty', 'attending': attending, 'users': attendingUsers.split(';')})
  }, [attendingUsers, setAttendingUsers]);

  useEffect(() => {
    updateAttendingUsers(attendingUsers, user, setAttendingUsers, setUser);
  }, [user, setUser]);

  function toggleUser(userId) {
    setUser(userId);
  }

  return (<>
    <BaseEventForm attending={attending} guests={guests} event="WelcomeParty" toggleAttendance={toggleAttendance} toggleUser={toggleUser}></BaseEventForm>
    </>
  )
}

function WeddingForm({ guests, updateResponses }) {
  const [attending, setAttending] = React.useState('');
  const [user, setUser] = React.useState('');
  const [attendingUsers, setAttendingUsers] = React.useState('');

  function toggleAttendance(val) {
    toggleTheAttendance(val, setAttending, setAttendingUsers);
  }

  updateResponses({'event': 'Wedding', 'attending': attending, 'users': []})

  useEffect(() => {
    updateResponses({'event': 'Wedding', 'attending': attending, 'users': attendingUsers.split(';')})
  }, [attendingUsers, setAttendingUsers]);

  useEffect(() => {
    updateAttendingUsers(attendingUsers, user, setAttendingUsers, setUser);
  }, [user, setUser]);

  function toggleUser(userId) {
    setUser(userId);
  }

  return (<>
    <BaseEventForm attending={attending} guests={guests} event="Wedding" toggleAttendance={toggleAttendance} toggleUser={toggleUser}></BaseEventForm>
    </>
  )
}

function BrunchForm({ guests, updateResponses }) {
  const [attending, setAttending] = React.useState('');
  const [user, setUser] = React.useState('');
  const [attendingUsers, setAttendingUsers] = React.useState('');

  function toggleAttendance(val) {
    toggleTheAttendance(val, setAttending, setAttendingUsers);
  }

  updateResponses({'event': 'Brunch', 'attending': attending, 'users': []})

  useEffect(() => {
    updateResponses({'event': 'Brunch', 'attending': attending, 'users': attendingUsers.split(';')})
  }, [attendingUsers, setAttendingUsers]);

  useEffect(() => {
    updateAttendingUsers(attendingUsers, user, setAttendingUsers, setUser);
  }, [user, setUser]);

  function toggleUser(userId) {
    setUser(userId);
  }

  return (<>
    <BaseEventForm attending={attending} guests={guests} event="Brunch" toggleAttendance={toggleAttendance} toggleUser={toggleUser}></BaseEventForm>
    </>
  )
}

function EventForm({ events, guests, invitationId }) {
  var responses = [];
  var shabResp = {};
  var brunchResp = {};
  var welcomePartyResp = {};
  var weddingResp = {};

  function updateResponses(response) {
    switch(response['event']) {
      case 'Shabbat': shabResp = response; break;
      case 'WelcomeParty': welcomePartyResp = response; break;
      case 'Wedding': weddingResp = response; break;
      case 'Brunch': brunchResp = response; break;
      default: break;
    }
  }

  var shabbatForm = events?.includes("Shabbat") ? <ShabbatForm guests={guests} updateResponses={updateResponses} />  : <></>
  var welcomePartyForm = events?.includes("WelcomeParty") ? <WelcomePartyForm guests={guests} updateResponses={updateResponses} /> : <></>
  var weddingForm = events?.includes("Wedding") ? <WeddingForm guests={guests} updateResponses={updateResponses} /> : <></>
  var brunchForm = events?.includes("Brunch") ? <BrunchForm guests={guests} updateResponses={updateResponses} /> : <></>

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.target.reset();

    if ( events.includes("Shabbat")) {
      responses.push(shabResp);
    }
    if ( events.includes("WelcomeParty")) {
      responses.push(welcomePartyResp);
    }
    if ( events.includes("Wedding")) {
      responses.push(weddingResp);
    }
    if ( events.includes("Brunch")) {
      responses.push(brunchResp);
    }

    // if any attending == '', set it to false.
    responses.forEach((resp) => {
      if (resp.attending == '') {
        resp.attending = 'false';
      }
    })

    // await axios.post('http://localhost:5000/api/invitation/' + invitationId + '/rsvp', {
    await axios.post('https://kitay-mechali-pipeline-kitay-mechali-service-708934323140.us-east1.run.app/api/invitation/' + invitationId + '/rsvp', {
      rsvp_response: responses
    }).then(function (response) {
      window.open("/confirmed", "_self");
    })
  };

  return (<>
      <form onSubmit={handleSubmit}>
        <FormControl>
          { shabbatForm }
          { welcomePartyForm }
          { weddingForm }
          { brunchForm }
          <br></br>
          <Button type="submit" variant="outlined">
            Submit
          </Button>
        </FormControl>
      </form>
    </>
  )
}

function RsvpInvitation({ guest }) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [invitationId, setInvitationId] = useState(''); 
  const [hasRsvpd, setHasRsvpd] = useState(false);
  const [rsvpsLoaded, setRsvpsLoaded] = useState(false);
  const [actualGuests, setActualGuests] = useState([]);

  var responseGuests = [];
  useEffect(() => {
    async function fetchData() {
      if (invitationId !== "") {
        const {data: rsvpResponses} = await axios.get('https://kitay-mechali-pipeline-kitay-mechali-service-vu3uk6fctq-ue.a.run.app/api/invitation/'+invitationId+'/responses');
        // const {data: rsvpResponses} = await axios.get('http://localhost:5000/api/invitation/'+response.key.id+'/responses');
        setHasRsvpd(rsvpResponses.response_found);
      }
    }
    fetchData();
    setRsvpsLoaded(true);
  }, [invitationId, setInvitationId])


  useEffect(() => {
    async function fetchData() {
      try {
        let guestId = guest.id;
        const {data: response} = await axios.get('https://kitay-mechali-pipeline-kitay-mechali-service-vu3uk6fctq-ue.a.run.app/api/users/'+guestId+'/invitation');
        actualEvents = response.events;
        responseGuests = response.guests;
        setInvitationId(response.key.id);
        let guestsForEvent = []
        for (let i = 0; i < responseGuests.length; i++) {
          const {data: userResponse} = await axios.get('https://kitay-mechali-pipeline-kitay-mechali-service-vu3uk6fctq-ue.a.run.app/api/users/'+responseGuests[i]);
          guestsForEvent.push(userResponse[0]);
        }
        setActualGuests(guestsForEvent);
      } catch (error) {
      }
    }
    fetchData();
    setHasLoaded(true);
  }, [guest]);

  return (
    <>
    {!hasRsvpd ? <EventForm events={actualEvents} guests={actualGuests} invitationId={invitationId} /> : <p>Looks like you already rsvp'd.</p>}
    </>);
}

function RsvpInfo() {
  const  [hasLoaded, setHasLoaded] = useState(false);
  const  [searchParams, setSearchParams] = useSearchParams();

	const  guestName = searchParams.get("guest");

  let selectGuest = React.useCallback(() => {
    foundGuest = true;
    setHasLoaded(false);
  });

  let fetchIsInvited = React.useCallback(async () => {
    if (guestName == null || guestName == "") {
      return;
    }

    try {
        const {data: response} = await axios.get('https://kitay-mechali-pipeline-kitay-mechali-service-vu3uk6fctq-ue.a.run.app/api/users/search/'+guestName);
        foundGuest = response.exactMatch;
        if (foundGuest) {
          guest = response.guests[0];
        } else if (response.guests.length > 0) {
          potentialGuests = response.guests;
          foundPotentialGuests = true;
        }

        setHasLoaded(true);
      } catch (error) {
      }
    }, [guestName, hasLoaded, foundGuest, searchParams, setSearchParams]);

  useEffect(() => {
    fetchIsInvited();
  }, [searchParams, setSearchParams]);

  fetchIsInvited();


  if (foundGuest) {
    return (
      <>
        <p>Found an invitation for: <b>{ guest.name }</b></p>
        <RsvpInvitation guest={guest}/>
      </>);
  } else if (foundPotentialGuests) {
    return (<><PotentialGuestSelect potentialGuests={potentialGuests} selectGuest={selectGuest} /></>);
  } else {
    let userNotFound =  guestName != null && guestName != "" ? (<p>User { guestName } not found.</p>) : <></>;
    return (
      <>
        { userNotFound }
        <Rsvp isSection={false} />
      </>);
  }
}

export default function RsvpDetail() {
  const [isAuth, setAuthed] = React.useState(false);

  if (!isAuth) {
    return (
      <>
        <PasswordPromptDialog setAuth={setAuthed} />
      </>);
  } else {
    return (
      <>
        <div id="rsvp" className="rsvp-info section">
          <h1 className="section-title">Rsvp</h1>
          <RsvpInfo />
        </div>
      </>);
  }
}