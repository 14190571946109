import React, { Component } from 'react';

import './styles.css';
import './../../App.css';

const welcomeMessage =
  "We are so excited to have you here as we start this new chapter of our lives together!"
  + " We built this website so you can find important information about our wedding"
  + " weekend and feel at ease as you start planning your trip to Paris. Peruse the site, "
  + " mark your calendars, and if you still have questions, you know where to reach us! We "
  + " are so grateful that you’re here and cannot wait to celebrate this simcha surrounded "
  + " by our loved ones.";

class Welcome extends Component {
  render() {
    return (
    <div id="welcome" className="welcome section">
      <h1 className="section-title">Bienvenue,</h1>
      <p className="welcome-details">{welcomeMessage}</p>
      <p className="welcome-details">Paris, here we come!!!!</p>
      <p className="welcome-details">Love,</p>
      <p className="welcome-details">Shira and Gabriel</p>
    </div>
    );
  }
}
export default Welcome;