import React, { Component } from 'react';

import './styles.css';
import './../../App.css';


const renaissance = {
  name: "Renaissance Paris Arc de Triomphe",
  rating: "5 star",
  price: "$$",
  link: "https://www.marriott.com/en-us/hotels/parwg-renaissance-paris-arc-de-triomphe-hotel"
}

const splendid = {
  name: "Hotel Splendide Etoile",
  rating: "4 star",
  price: "$$$",
  link: "https://www.hsplendid.com/"
}

const missFuller = {
  name: "Miss Fuller",
  rating: "4 star",
  price: "$$",
  link: "https://www.missfullerhotel.com/en/"
}

const dadou = {
  name: "Dadou Paris",
  rating: "4 star",
  price: "$$$",
  link: "https://www.dadou.paris/en/"
}

const regenceEtoile = {
  name: "Hotel Regence Etoile",
  rating: "4 star",
  price: "$$",
  link: "https://www.hotelregenceetoile.com"
}

const tsuba = {
  name: "Le Tsuba Hotel Paris",
  rating: "4 star",
  price: "$$",
  link: "https://www.tsubahotel.com"
}

const maisonElle = {
  name: "Maison Elle Paris Etoile",
  rating: "4 star",
  price: "$$",
  link: "https://maisonelle.fr/en"
}

const stellaEtoile = {
  name: "Hotel Stella Etoile",
  rating: "3 star",
  price: "$$",
  link: "https://www.hotelstella.com/en"
}

const tilsitt = {
  name: "Tilsitt Etoile",
  rating: "3 star",
  price: "$$",
  link: "https://www.tilsitt.com/en/"
}

const fertel = {
  name: "Hotel  Fertel Etoile Paris",
  rating: "3 star",
  price: "$$",
  link: "https://www.hotelferteletoile.com/"
}

const magda = {
  name: "Hotel Magda Champs Elysees",
  rating: "3 star",
  price: "$$",
  link: "https://www.arcotel-magdachampselysees.com/en/"
}

function HotelCard({ hotel }) {
  return (
    <>
    <p><a href={hotel.link} target='_blank'>{hotel.name}</a> ({hotel.rating})</p>
    </>
  )
}

function HotelList() {
const hotels = [renaissance, splendid, missFuller, dadou, regenceEtoile, tsuba, maisonElle, stellaEtoile, tilsitt, fertel, magda];
  let hotelsList = hotels.map((h, index) => <HotelCard key={index} hotel={h}/>);
  return (<><ul>{hotelsList}</ul></>)
}

class Travel extends Component {
  render() {
    return (
    <>
      <hr class="section-divider"/>
      <div id="travel" className="Travel travel section travel-section">
        <h1 class="section-title">Accommodations</h1>
        <p>We're so grateful that our family and friends are travelling all the way to Paris to celebrate with us!</p>
        <p>Hotel blocks are not standard in France, however we plan to have wedding day shuttles pickup & dropoff around the Arc de Triomphe.</p>
        <p>Below is a list of several hotels in the area, however options are endless!</p>
        <HotelList />
        <br></br>
        <br></br>
        <div id="transportation">
        <h1 class="section-title">Transportation</h1>
        <p>The venue is located around 50 minutes south west of Paris. We will provide transportation to and from the wedding venue on Sunday, August 31st, at times that are still to be determined.</p>
        <p>If you require greater flexibility, we suggest you plan for your own transportation. While you may be able to take a taxi or Uber on demand to the venue, it will be more complicated on the way back. Please rent a car, or contact Taxi Rambouillet (+33 6 08 71 92 27) to arrange your return transportation several days in advance!</p>
        </div>
      </div>
    </>
    );
  }
}
export default Travel;